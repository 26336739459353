require("jquery")
import { Controller } from "stimulus"
import '../packs/bootstrap_custom.js'

export default class extends Controller {

  connect() {
    $("a.popoverlink", this.element).popover({
      trigger: 'click'
    })

    $(".popover-hover", this.element).popover({
      trigger: 'hover'
    })
  }
}
