import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source", "target"];

  connect() {
    this.handleChecked();
  }

  handleChecked() {
    this.targetTarget.disabled = !this.sourceTarget.checked;
  }
}
