import { Controller } from "stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static targets = ["dateInput"]

  connect() {
    flatpickr(this.dateInputTarget, {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      defaultDate: this.data.get("selectedDate"),
      minDate: "today",
      enable: JSON.parse(this.data.get("enabledDates")),
      wrap: true
    })
  }
}
