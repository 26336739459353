import { Controller } from 'stimulus'
import { dawaAutocomplete } from "dawa-autocomplete2"

export default class extends Controller {
  static targets = ["searchField", "dawaAddressIdField", "dawaAddressStringField", "dawaAddressCoordsField"]

  connect() {
    this.autocomplete = dawaAutocomplete(this.searchFieldTarget,  {
      select: (selected) => {
        this.dawaAddressIdFieldTarget.value = selected.data.id
      }
    })
  }

  disconnect() {
    this.autocomplete.destroy()
  }

  hideOptions(event) {
    if (event.code == "Escape") {
      this.searchFieldTarget.blur()
    }
  }
}
