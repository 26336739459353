/*
How to use:

<div data-controller="listjs">
  <table">
    <thead>
      <tr>
        <th class="sort-some_identifier">Table Header</th>
      </tr>
    </thead>
    <tbody data-target="listjs.list" default-sort-asc="sort-some_identifier">
      <input data-target="listjs.search" data-list-target="search" type="text" placeholder="Search">
      <tr>
        <td class="sort-some_identifier">Field to sort</th>
      </tr>
    </tbody>
  </table>
</div>

Of note is:
- The data-controller attribute on the div element
- The data-target="listjs.list" attribute on the tbody element
- The data-target="listjs.search" attribute on the input element
- The class="sort-some_identifier"
  - This must be present on all TD elements that should be searchable, prefixed with 'sort-'
  - Can be ommited on the TH element, but then the header cannot be clicked to sort the table
- The default-sort-asc="sort-some_identifier" attribute on the tbody element allows defining a column and direction to sort by default
  - default-sort-desc="sort-some_identifier" can be used to sort descending by default

In newer versions of stimulus, the format of the target is, for example: data-listjs-target="search"
*/

import { Controller } from "stimulus"
import List from "list.js"

export default class extends Controller {
  static targets = ["search", "list"]

  connect() {

    // Add information to elements, needed by listjs
    this.listTarget.classList.add("list");
    this.searchTarget.classList.add("search");

    let allClassNames = []
    let allElements = this.element.getElementsByTagName('*')

    // Get all class names that start with 'sort-'
    for(let i = 0; i < allElements.length; i++) {
      let elementType = allElements[i].nodeName;
      let classes = allElements[i].className.split(/\s+/)
      
      for(let j = 0; j < classes.length; j++) {
        if(classes[j].startsWith('sort-')) {
          allClassNames.push(classes[j])
          
          // add sort class to headers, if needed
          if(elementType == 'TH') {
            allElements[i].classList.add('sort')
            allElements[i].setAttribute('data-sort', classes[j]);
          }
        }
      }
    }

    // Remove duplicates
    let uniqueClassNames = [...new Set(allClassNames)]

    // listjs setup
    var options = new List(this.element, {
      valueNames: uniqueClassNames
    })

    var vehicleList = new List("main-listjs-container", options);

    // Sort by default
    let defaultSort = this.listTarget.getAttribute('default-sort-asc') || this.listTarget.getAttribute('default-sort-desc')

    if(defaultSort) {
      let sortDirection = 'desc'
      if(this.listTarget.getAttribute('default-sort-asc')) {
        sortDirection = 'asc'
      }
      
      vehicleList.sort(defaultSort, {
        order: sortDirection
      })
    }
  }
}
